* {
    margin: 0;
    border: none;
    background-color: transparent;
    text-decoration: none;
    outline: none;
    box-sizing: border-box;
    padding: 0;
}


body {
    background-color: var(--background-content);
    height: 100%;
    width: 100%;
    font-family: "Montserrat", Lato, sans-serif;
    font-weight: 400;
    font-size: .9375rem;
    color: #fff;
    margin: 0;
    line-height: 1.5;
    --red: #f12c4c;
    --white: #fff;
    --black: #262f3c;
    --gray: #55657e;
    --fill-color-blue: #1677ff;
    --fill-color-red: #f12c4c;
}

.hide-scrollbar .drag-scroll-content::-webkit-scrollbar-thumb {
    background: transparent;

}
  

@font-face {
    font-family: Montserrat;
    src: url(assets/fonts/Montserrat-Regular.otf);
  }

a, svg {
    text-decoration: none;
    color: #969696;
}

.button-primary {
    cursor: pointer !important;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: .8125rem;
    font-weight: 700;
    border-radius: .25rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    height: 36px;
}

:root {
    --background-content: #1a1c24;
}

.hidden {
    display: none !important;
}

/* width */
::-webkit-scrollbar {
    width: 6px;
    border-radius: 15px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 15px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #333541;
    border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #383a47;
    border-radius: 15px;
}

.relative {
    position: relative;
}

.mb-4 {
    margin-bottom: 1.25rem;
}

.mb-6 {
    margin-bottom: 1.875rem;
}

.mt-4 {
    margin-top: 1.25rem;
}

.mt-2 {
    margin-top: 1.15rem;
}

.mt-6 {
    margin-top: 1.875rem;
}

.text-sm {
    font-size: .8125rem;
}

.font-bold {
    font-weight: 700;
}

.uppercase {
    text-transform: uppercase;
}

.text-md {
    font-size: .98rem;
}

.p-4 {
    padding: 1.25rem;
}

.text-silver {
    color: silver;
}

.text-gold {
    color: #e9b10b;
}

.font-bold {
    font-weight: 700;
}

.text-light-grey-2 {
    color: silver;
}

.border-solid {
    border: 1px solid;
}

@font-face {
    font-family: 'American Captain';
    src: url('/assets/fonts/american-captain.ttf');
}

.cdk-global-overlay-wrapper {
    height: 100%;
    min-width: 100%;
}

.cdk-overlay-pane {
    margin: auto;
    height: 100%;
    min-width: 100%;
}

.mat-dialog-container {
    z-index: 9999999;
    padding: 0 !important;
    display: flex !important;
    justify-content: center !important;
    padding: 15px !important;
    position: fixed !important;
    align-items: center !important;
}

.coin svg {
    width: 22px;
    height: 22px;
    fill: #e9b10b;
    margin-right: 5px;
}

.input input {
    position: relative;
    width: 100%;
    height: 3.125rem;
    background-color: #141419;
    border-radius: .25rem;
    padding-left: .9375rem;
    padding-right: .9375rem;
    border-width: 1px;
    border-color: #333541;
    color: #dcdcdc;
    z-index: 10;
    outline: none!important;
}

.button-save {
    color: #1a1c24;
    background: #02bf4d;
    -webkit-box-shadow: inset 0 -3px 0 0 #00963b;
    box-shadow: inset 0 -3px 0 0 #00963b;
    padding-bottom: 3px;
    padding: 8px 15px;
    cursor: pointer;
    font-size: medium;
    float: right;
    color: black;
    font-weight: 700;
}

.justify-items-center {
    justify-items: center;
}

.justify-space {
    justify-content: space-between;
}

.align-center {
    align-items: center;
}

.flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.flex-column {
    flex-direction: column;
}

.pr-4 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
}

.pt-6 {
    padding-top: 1.825rem;
}

.grey-4 {
    border-color: #333541;
}

.bg-dark-grey-1 {
    background-color: #141419;
}

.px-1 {
    padding-left: .3125rem;
    padding-right: .3125rem;
}

.heading-small {
    font-family: 'American Captain';
    font-weight: 400;
    font-size: 1.25rem;
    color: #dcdcdc;
    letter-spacing: .025em;
    line-height: 1.25;
}

.text-lg {
    font-size: 1.125rem;
}

.text-light-grey-3 {
    color: #969696;
}
.ml-1 {
    margin-left: .3125rem;
}

h1 {
    font-size: 2rem;
    font-family: 'American Captain';
    font-weight: 400;
    color: #dcdcdc;
    letter-spacing: .025em;
    line-height: 1.25;
    margin-bottom: 1.5625rem;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}